import {
  ClientRect,
  CollisionDetection,
  DndContext,
  DragStartEvent,
  MeasuringStrategy,
  MouseSensor,
  getClientRect,
  rectIntersection,
  useDndMonitor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useCallback, useRef } from "react";

import { Debug } from "./Debug";
import type { DragDropContextProps } from "./beautiful-dnd";
import { StoreProvider, useStore } from "./store";
import { CollisionRectRefContext } from "./utils/collisionRect";
import { getDraggableDecriptor } from "./utils/query";

export const DragDropContext: React.FC<
  DragDropContextProps & { debug?: boolean }
> = ({ children, debug, ...props }) => {
  // Add a small distance threshold to allow clicks
  // No keyboard support for now.
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const collisionRectRef = useRef<ClientRect>();

  // We highjack the collision detection to make it match
  // beautiful DnD behaviour.
  const collisionDetection = useCallback<CollisionDetection>((args) => {
    const { active, droppableContainers } = args;
    collisionRectRef.current = args.collisionRect;
    return rectIntersection({
      ...args,
      // Only look for collision on matching types
      droppableContainers: droppableContainers.filter((droppable) => {
        const draggableData = getDraggableDecriptor(active);
        const droppableData = getDraggableDecriptor(droppable);
        return draggableData.type === droppableData.type;
      }),
    });
  }, []);

  return (
    <DndContext
      collisionDetection={collisionDetection}
      sensors={sensors}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.WhileDragging,
          frequency: 100,
        },
      }}
      autoScroll={{
        layoutShiftCompensation: false,
      }}
      onDragStart={useCallback((event: DragStartEvent) => {
        if (!(event.activatorEvent.target instanceof HTMLElement)) return;
        collisionRectRef.current = getClientRect(event.activatorEvent.target);
      }, [])}
      onDragEnd={useCallback(() => {
        collisionRectRef.current = undefined;
      }, [])}
    >
      <CollisionRectRefContext.Provider value={collisionRectRef}>
        <StoreProvider>
          {debug && <Debug />}
          <EventCallbacks {...props} />
          {children}
        </StoreProvider>
      </CollisionRectRefContext.Provider>
    </DndContext>
  );
};

const EventCallbacks = ({
  onDragStart,
  onDragUpdate,
  onDragEnd,
}: Omit<DragDropContextProps, "children">) => {
  const { state } = useStore();
  useDndMonitor({
    onDragStart({ active }) {
      const data = getDraggableDecriptor(active);
      onDragStart?.({
        draggableId: data.id,
        source: { droppableId: data.droppableId, index: data.index },
        type: data.type,
      });
    },
    onDragMove({ active }) {
      if (state.phase !== "DRAGGING") return;
      const data = getDraggableDecriptor(active);
      onDragUpdate?.({
        draggableId: data.id,
        source: { droppableId: data.droppableId, index: data.index },
        type: data.type,
        destination: state.impact.destination,
      });
    },
    onDragEnd({ active }) {
      if (state.phase !== "DRAGGING") return;
      const data = getDraggableDecriptor(active);
      onDragEnd?.({
        draggableId: data.id,
        source: { droppableId: data.droppableId, index: data.index },
        type: data.type,
        destination: state.impact.destination,
      });
    },
  });
  return null;
};
