import { useDndContext, useDndMonitor } from "@dnd-kit/core";
import { useMemo, useReducer } from "react";

import { createSafeContext } from "@/services/hooks/useSafeContext";

import { useCollisionRectRef } from "../utils/collisionRect";
import { Action } from "./actions";
import { reducer } from "./reducer";
import { type State, idle } from "./state";

const StoreContext = createSafeContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>();

export const useStore = StoreContext.makeSafeHook("useStore", "StoreProvider");

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, idle);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useDndEvents(dispatch);

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

const useDndEvents = (dispatch: React.Dispatch<Action>) => {
  const context = useDndContext();
  const collisionRectRef = useCollisionRectRef();

  useDndMonitor({
    onDragStart(event) {
      dispatch({
        type: "DRAG_START",
        context,
        payload: event,
      });
    },

    onDragMove(event) {
      dispatch({
        type: "DRAG_MOVE",
        context: { ...context, collisionRect: collisionRectRef.current! },
        payload: event,
      });
    },

    onDragOver(event) {
      dispatch({
        type: "DRAG_OVER",
        context: { ...context, collisionRect: collisionRectRef.current! },
        payload: event,
      });
    },

    onDragCancel(event) {
      dispatch({
        type: "DRAG_CANCEL",
        context,
        payload: event,
      });
    },

    onDragEnd(event) {
      dispatch({
        type: "DRAG_END",
        context,
        payload: event,
      });
    },
  });
};
