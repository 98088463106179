import type { Coordinates } from "@dnd-kit/utilities";

const curves = {
  outOfTheWay: "cubic-bezier(0.2, 0, 0, 1)",
};

const timings = {
  outOfTheWay: 0.2,
};

export const outOfTheWayTiming: string = `${timings.outOfTheWay}s ${curves.outOfTheWay}`;

export const moveTo = (
  offset: Coordinates,
): React.CSSProperties["transform"] =>
  offset.x || offset.y ? `translate(${offset.x}px, ${offset.y}px)` : undefined;

export const zIndexOptions = {
  dragging: 5000,
};
