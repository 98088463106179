import { useDraggable } from "@dnd-kit/core";
import { useMemo } from "react";
import { usePrevious } from "swash/utils/usePrevious";

import { useDroppableContext } from "./DroppableContext";
import type {
  DraggableProps,
  DraggableProvided,
  DraggableStateSnapshot,
} from "./beautiful-dnd";
import { useStore } from "./store";
import { moveTo, outOfTheWayTiming, zIndexOptions } from "./styles";
import type { DraggableDescriptor } from "./types";
import { useCollisionRectRef } from "./utils/collisionRect";

export const Draggable: React.FC<DraggableProps> = ({
  children,
  draggableId,
  index,
  isDragDisabled,
}) => {
  const droppableContext = useDroppableContext();

  const descriptor = useMemo(
    (): DraggableDescriptor => ({
      id: draggableId,
      index,
      droppableId: droppableContext.droppableId,
      type: droppableContext.type,
    }),
    [draggableId, droppableContext.droppableId, droppableContext.type, index],
  );

  const { setNodeRef, isDragging, attributes, listeners } = useDraggable({
    id: draggableId,
    disabled: isDragDisabled,
    data: descriptor,
  });

  const style = useDraggableStyle(draggableId, droppableContext.type);

  const provided = useMemo(
    (): DraggableProvided => ({
      innerRef: setNodeRef,
      draggableProps: {
        style,
        "data-rbd-draggable-context-id": "Home-Edition",
        "data-rbd-draggable-id": draggableId,
      },
      dragHandleProps: {
        ...attributes,
        ...listeners,
        "data-rbd-drag-handle-context-id": "Home-Edition",
        "data-rbd-drag-handle-draggable-id": draggableId,
      },
    }),
    [attributes, draggableId, listeners, setNodeRef, style],
  );

  const snapshot = useMemo(
    (): DraggableStateSnapshot => ({
      isDragging,
    }),
    [isDragging],
  );

  return children(provided, snapshot);
};

const useDraggableStyle = (draggableId: string, type: string) => {
  const { state } = useStore();
  const previousState = usePrevious(state);
  const collisionRectRef = useCollisionRectRef();

  return useMemo((): React.CSSProperties => {
    if (state.phase === "DRAGGING" && previousState?.phase === "IDLE") {
      if (state.critical.draggable.type !== type) return {};
      if (state.critical.draggable.id === draggableId) {
        // Active - Just starting the drag
        return {
          transition: "none",
        };
      } else {
        // Other - Just starting the drag
        return {
          transform: state.afterCritical.displacedIds.includes(draggableId)
            ? moveTo(state.afterCritical.displacedBy)
            : undefined,
          transition: "none",
          pointerEvents: "none",
        };
      }
    }

    if (state.phase === "DRAGGING") {
      if (state.critical.draggable.type !== type) return {};
      if (state.critical.draggable.id === draggableId) {
        // Active - Dragging
        return {
          // ## Placement
          position: "fixed",

          // ## Sizing
          // Locking these down as pulling the node out of the DOM could cause it to change size
          boxSizing: "border-box",
          width: state.initial.box.width,
          height: state.initial.box.height,

          // ## Movement
          margin: 0,
          left: 0,
          top: 0,
          transform: collisionRectRef.current
            ? `translate3d(${collisionRectRef.current.left}px, ${collisionRectRef.current.top}px, 0)`
            : undefined,
          transition: "none",

          // ## Layering
          zIndex: zIndexOptions.dragging,

          // ## Blocking any pointer events on the dragging or dropping item
          // global styles on cover while dragging
          pointerEvents: "none",

          // ## Additional styling
          cursor: "grabbing",
        };
      } else {
        // Other - Dragging
        return {
          transition: `transform ${outOfTheWayTiming}`,
          transform: state.impact.displacedIds.includes(draggableId)
            ? moveTo(state.impact.displacedBy)
            : undefined,
          pointerEvents: "none",
        };
      }
    }

    return {};
  }, [draggableId, type, state, previousState?.phase, collisionRectRef]);
};
