import { ClientRect, useDndContext } from "@dnd-kit/core";
import { useEffect, useRef } from "react";
import { Portal } from "swash/Portal";
import { useEventCallback } from "swash/utils/useEventCallback";

import { useStore } from "./store";
import { useCollisionRectRef } from "./utils/collisionRect";

export const Debug = () => {
  return (
    <>
      <StateDebug />
      <VisualDebug />
    </>
  );
};

const StateDebug = () => {
  const { state } = useStore();
  return (
    <code className="fixed z-[1000] whitespace-pre bg-white p-2 text-2xs">
      {JSON.stringify(state, null, 2)}
    </code>
  );
};

const VisualDebug = () => {
  const overlayRef = useRef<HTMLCanvasElement>(null);

  const collisionRectRef = useCollisionRectRef();

  const { droppableRects, over } = useDndContext();

  const animate = useEventCallback(() => {
    const ctx = overlayRef.current?.getContext("2d");
    if (!ctx) return;

    const draw = (color: string, rect: ClientRect) => {
      ctx.globalAlpha = 0.75;
      ctx.strokeStyle = color;
      ctx.strokeRect(
        rect.left + 0.5,
        rect.top + 0.5,
        rect.width - 1,
        rect.height - 1,
      );

      ctx.globalAlpha = 0.05;
      ctx.fillStyle = color;
      ctx.fillRect(rect.left, rect.top, rect.width, rect.height);
    };

    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

    droppableRects.forEach((rect, id) => {
      draw(over?.id === id ? "red" : "yellow", rect);
    });

    if (collisionRectRef.current) {
      draw("blue", collisionRectRef.current);
    }
  });

  useEffect(() => {
    let raf = 0;
    const loop = () => {
      if (!overlayRef.current) return;
      animate();
      raf = requestAnimationFrame(loop);
    };
    raf = requestAnimationFrame(loop);
    return () => cancelAnimationFrame(raf);
  }, [animate]);

  return (
    <Portal>
      <canvas
        ref={overlayRef}
        className="pointer-events-none fixed inset-0 z-[10000]"
        width={window.innerWidth}
        height={window.innerHeight}
      />
    </Portal>
  );
};
