import { useMemo } from "react";
import { usePrevious } from "swash/utils/usePrevious";

import { useStore } from "./store";
import { outOfTheWayTiming } from "./styles";

type PlaceholderProps = {
  droppableId: string;
  type: string;
};

export const Placeholder: React.FC<PlaceholderProps> = ({
  droppableId,
  type,
}) => {
  const style = usePlaceholderStyle(droppableId, type);

  return <div style={style} data-rbd-placeholder-context-id="Home-Edition" />;
};

const usePlaceholderStyle = (droppableId: string, type: string) => {
  const { state } = useStore();
  const previousState = usePrevious(state);

  return useMemo((): React.CSSProperties => {
    if (state.phase === "DRAGGING" && previousState?.phase === "IDLE") {
      if (state.critical.droppable.type !== type) return HiddenStyle;
      // Just starting the drag
      const isHome = state.critical.droppable.id === droppableId;
      return {
        ...BasePlaceholderStyle,
        width: isHome ? Math.max(1, state.afterCritical.displacedBy.x) : 0,
        height: isHome ? Math.max(1, state.afterCritical.displacedBy.y) : 0,
        transition: "none",
      };
    }

    if (state.phase === "DRAGGING") {
      if (state.critical.draggable.type !== type) return HiddenStyle;
      // Dragging
      const isDestination =
        state.impact.destination?.droppableId === droppableId;
      return {
        ...BasePlaceholderStyle,
        width: isDestination ? Math.max(1, state.impact.displacedBy.x) : 0,
        height: isDestination ? Math.max(1, state.impact.displacedBy.y) : 0,
        transition: `height ${outOfTheWayTiming}, width ${outOfTheWayTiming}`,
      };
    }

    return HiddenStyle;
  }, [droppableId, type, state, previousState?.phase]);
};

const BasePlaceholderStyle: React.CSSProperties = {
  // ## Avoiding collapsing
  // Avoiding the collapsing or growing of this element when pushed by flex child siblings.
  // We have already taken a snapshot the current dimensions we do not want this element
  // to recalculate its dimensions
  // It is okay for these properties to be applied on elements that are not flex children
  flexShrink: 0,
  flexGrow: 0,
  margin: 0,

  // Just a little performance optimisation: avoiding the browser needing
  // to worry about pointer events for this element
  pointerEvents: "none",
};

const HiddenStyle: React.CSSProperties = { display: "none" };
