import { Button } from "swash/Button";
import {
  IoDesktopOutline,
  IoMoon,
  IoMoonOutline,
  IoPhonePortraitOutline,
} from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";

export const StatusBar = (props: React.ComponentProps<"div">) => (
  <div
    {...props}
    className={cn("flex bg-dusk-bg-stronger text-white", props.className)}
  />
);

export const StatusBarSpacer = () => <div className="flex-1" />;

export const StatusBarGroup = (props: React.ComponentProps<"div">) => (
  <div {...props} className={cn("flex gap-2 p-2", props.className)} />
);

export type Device = "mobile" | "desktop";

export const StatusBarDeviceGroup = ({
  value,
  onChange,
  children,
}: {
  value: Device;
  onChange: (value: Device) => void;
  children?: React.ReactNode;
}) => (
  <StatusBarGroup>
    <Tooltip tooltip="Mode mobile">
      <Button
        iconOnly
        appearance="text"
        scale="sm"
        variant="secondary"
        disabled={value === "mobile"}
        aria-pressed={value === "mobile"}
        onClick={() => onChange("mobile")}
      >
        <IoPhonePortraitOutline />
      </Button>
    </Tooltip>
    <Tooltip tooltip="Mode ordinateur">
      <Button
        iconOnly
        appearance="text"
        scale="sm"
        variant="secondary"
        disabled={value === "desktop"}
        aria-pressed={value === "desktop"}
        onClick={() => onChange("desktop")}
      >
        <IoDesktopOutline />
      </Button>
    </Tooltip>
    {children}
  </StatusBarGroup>
);

export type ColorMode = "dark" | "light";

export const StatusBarColorModeGroup = ({
  value,
  onChange,
  children,
}: {
  value: ColorMode;
  onChange: (value: ColorMode) => void;
  children?: React.ReactNode;
}) => (
  <StatusBarGroup>
    <Tooltip tooltip="Mode sombre">
      <Button
        appearance="text"
        variant="secondary"
        iconOnly
        scale="sm"
        onClick={() => onChange(value === "light" ? "dark" : "light")}
        aria-pressed={value === "dark"}
      >
        {value === "dark" ? <IoMoon /> : <IoMoonOutline />}
      </Button>
    </Tooltip>
    {children}
  </StatusBarGroup>
);
