import { createContext, useContext } from "react";
import { useId } from "swash/utils/hooks";

import { Draggable } from "../../dnd";

const ModernDraggableCapsuleContext = createContext();

export function ModernDraggableCapsule({ draggableId, index, children }) {
  const labelledBy = useId();
  return (
    <ModernDraggableCapsuleContext.Provider value={labelledBy}>
      <Draggable draggableId={draggableId} index={index}>
        {(provided, { isDragging }) => (
          <div
            ref={provided.innerRef}
            className="group relative flex items-center gap-2 outline-0"
            data-testid="draggable-capsule"
            data-dragging={isDragging}
            aria-labelledby={labelledBy}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            {children}
          </div>
        )}
      </Draggable>
    </ModernDraggableCapsuleContext.Provider>
  );
}

export const useModernDraggableCapsuleLabelId = () => {
  const id = useContext(ModernDraggableCapsuleContext);
  return id ?? null;
};
