import { useDroppable } from "@dnd-kit/core";
import { useUniqueId } from "@dnd-kit/utilities";
import { useMemo } from "react";

import { DroppableContext, DroppableContextValue } from "./DroppableContext";
import { Placeholder } from "./Placeholder";
import type {
  DroppableProps,
  DroppableProvided,
  DroppableStateSnapshot,
} from "./beautiful-dnd";
import { useStore } from "./store";
import { DroppableDescriptor } from "./types";

export const Droppable: React.FC<DroppableProps> = ({
  droppableId: idProps,
  type = "DEFAULT",
  direction = "vertical",
  isDropDisabled,
  children,
}) => {
  const droppableId = useUniqueId("droppable", idProps);

  const descriptor = useMemo(
    (): DroppableDescriptor => ({
      id: droppableId,
      direction,
      type,
      isEnable: !isDropDisabled,
    }),
    [direction, droppableId, isDropDisabled, type],
  );

  const { setNodeRef, isOver } = useDroppable({
    id: droppableId,
    disabled: isDropDisabled,
    data: descriptor,
  });

  const droppableContext = useMemo(
    (): DroppableContextValue => ({
      droppableId,
      direction,
      type,
    }),
    [direction, droppableId, type],
  );

  const placeholder = usePlaceholder(droppableId, type);

  const provided = useMemo(
    (): DroppableProvided => ({
      innerRef: setNodeRef,
      droppableProps: {
        "data-rbd-droppable-id": droppableId,
        "data-rbd-droppable-context-id": "Home-Edition",
      },
      placeholder,
    }),
    [setNodeRef, droppableId, placeholder],
  );

  const snapshot = useMemo(
    (): DroppableStateSnapshot => ({
      isDraggingOver: isOver,
    }),
    [isOver],
  );

  return (
    <DroppableContext.Provider value={droppableContext}>
      {children(provided, snapshot)}
    </DroppableContext.Provider>
  );
};

const usePlaceholder = (droppableId: string, type: string) => {
  const { state } = useStore();
  return useMemo(() => {
    if (state.phase === "IDLE") return null;
    return <Placeholder droppableId={droppableId} type={type} />;
  }, [droppableId, type, state.phase]);
};
