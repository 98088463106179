import {
  Active,
  ClientRect,
  DndContextDescriptor,
  Over,
  getClientRect,
} from "@dnd-kit/core";
import { Coordinates } from "@dnd-kit/utilities";

import { DND_TYPES } from "@/config/constants";

import { State } from "../store";
import {
  DragImpact,
  DraggableDimension,
  DroppableDescriptor,
  DroppableDimension,
  LiftEffect,
} from "../types";
import { Axis, getAxis } from "./axis";
import { origin, patch } from "./coordinates";
import {
  getDraggableDimension,
  getDraggablesInside,
  getDroppableDimension,
} from "./query";

const NoImpact: DragImpact = {
  displacedBy: origin,
  displacedIds: [],
  destination: null,
};

export const computeLiftEffect = ({
  draggable,
  home,
  context,
}: {
  draggable: DraggableDimension;
  home: DroppableDescriptor;
  context: DndContextDescriptor;
}): LiftEffect => {
  const axis = getAxis(home.direction);

  const displacedBy = getDisplacement(draggable, axis);

  const insideHome = getDraggablesInside(home.id, context);

  // in a list that does not start at 0 the descriptor.index might be different from the index in the list
  // eg a list could be: [2,3,4]. A descriptor.index of '2' would actually be in index '0' of the list
  const rawIndex = insideHome.findIndex(
    (other) => draggable.descriptor.id === other.descriptor.id,
  );

  const ids = insideHome.slice(rawIndex + 1).map((item) => item.descriptor.id);

  return {
    displacedBy,
    displacedIds: ids,
  };
};

export const computeImpact = (
  active: Active,
  over: Over | null,
  previous: State & { phase: "DRAGGING" },
  context: DndContextDescriptor & { collisionRect: ClientRect },
): DragImpact => {
  // not dragging over anything
  if (!over) {
    // A big design decision was made here to collapse the home list
    // when not over any list. This yielded the most consistently beautiful experience.
    return NoImpact;
  }

  const destination = getDroppableDimension(over.id, context);
  const draggable = getDraggableDimension(active.id, context);
  const insideDestination = getDraggablesInside(over.id, context);

  const isTransitioning = insideDestination.some((child) => {
    if (child.descriptor.id === draggable.descriptor.id) return false;
    return child.element
      .getAnimations()
      .some((animation) => animation instanceof CSSTransition);
  });
  if (isTransitioning) return previous.impact;

  return getReorderImpact({
    targetRect: context.collisionRect,
    draggable,
    destination,
    insideDestination,
    dragImpactedIds: previous.impact.displacedIds,
  });
};

const getReorderImpact = ({
  targetRect,
  draggable,
  destination,
  insideDestination,
  dragImpactedIds,
}: {
  targetRect: ClientRect;
  draggable: DraggableDimension;
  destination: DroppableDimension;
  insideDestination: DraggableDimension[];
  dragImpactedIds: string[];
}): DragImpact => {
  const axis = getAxis(destination.descriptor.direction);
  const displacedBy = getDisplacement(draggable, axis);

  const targetStart = targetRect[axis.start];
  const targetEnd = targetRect[axis.end];

  const closest = insideDestination
    .filter((child) => child.descriptor.id !== draggable.descriptor.id)
    .find((child) => {
      const childRect = getClientRect(child.element);
      const childCenter = (childRect[axis.start] + childRect[axis.end]) / 2;

      const isDisplaced = dragImpactedIds.includes(child.descriptor.id);

      // Note: We change things when moving *past* the child center - not when it hits the center
      // If we make it when we *hit* the child center then there can be
      // a hit on the next update causing a flicker.
      //  - Update 1: targetBottom hits center => displace backwards
      //  - Update 2: targetStart is now hitting the displaced center => displace forwards
      //  - Update 3: goto 1 (boom)

      // Item has been shifted forward.
      // Remove displacement when targetEnd moves forward past the displaced center
      if (isDisplaced) {
        return targetEnd <= childCenter;
      }

      // Item is behind the dragging item
      // We want to displace it if the targetStart goes *backwards past* the childCenter
      return targetStart < childCenter;
    });

  const newIndex = atIndex({
    draggable,
    closest,
    inHomeList: draggable.descriptor.droppableId === destination.descriptor.id,
  });

  // TODO: index cannot be null?
  // otherwise return null from there and return empty impact
  // that was calculate reorder impact does not need to account for a null index
  return calculateReorderImpact({
    draggable,
    insideDestination,
    destination,
    displacedBy,
    index: newIndex,
  });
};

const atIndex = ({
  draggable,
  closest,
  inHomeList,
}: {
  draggable: DraggableDimension;
  closest: DraggableDimension | undefined;
  inHomeList: boolean;
}) => {
  if (!closest) return null;
  if (!inHomeList) return closest.descriptor.index;
  if (closest.descriptor.index > draggable.descriptor.index) {
    return closest.descriptor.index - 1;
  }
  return closest.descriptor.index;
};

const calculateReorderImpact = ({
  draggable,
  insideDestination,
  destination,
  displacedBy,
  index,
}: {
  draggable: DraggableDimension;
  insideDestination: DraggableDimension[];
  destination: DroppableDimension;
  displacedBy: Coordinates;
  index: number | null;
}): DragImpact => {
  const inHomeList =
    draggable.descriptor.droppableId === destination.descriptor.id;

  // Go into last spot of list
  if (index == null) {
    return goAtEnd({
      insideDestination,
      inHomeList,
      displacedBy,
      destination,
    });
  }

  // this might be the dragging item
  const match = insideDestination.find(
    (child: DraggableDimension) => child.descriptor.index === index,
  );

  if (!match) {
    return goAtEnd({
      insideDestination,
      inHomeList,
      displacedBy,
      destination,
    });
  }

  const sliceFrom = insideDestination.indexOf(match);
  const impacted = insideDestination
    .filter((child) => child.descriptor.id !== draggable.descriptor.id)
    .slice(sliceFrom)
    .map((draggable) => draggable.descriptor.id);

  return {
    displacedIds: impacted,
    displacedBy,
    destination: {
      droppableId: destination.descriptor.id,
      index,
    },
  };
};

const goAtEnd = ({
  insideDestination,
  inHomeList,
  displacedBy,
  destination,
}: {
  insideDestination: DraggableDimension[];
  inHomeList: boolean;
  displacedBy: Coordinates;
  destination: DroppableDimension;
}): DragImpact => {
  const newIndex = getIndexOfLastItem(insideDestination, {
    inHomeList,
  });
  return {
    displacedIds: [],
    displacedBy,
    destination: {
      droppableId: destination.descriptor.id,
      index: newIndex,
    },
  };
};

const getIndexOfLastItem = (
  draggables: DraggableDimension[],
  options: {
    inHomeList: boolean;
  },
) => {
  if (!draggables.length) return 0;
  const indexOfLastItem = draggables[draggables.length - 1]!.descriptor.index;
  // When in a foreign list there will be an additional one item in the list
  return options.inHomeList ? indexOfLastItem : indexOfLastItem + 1;
};

const getDisplacement = (
  draggable: DraggableDimension,
  axis: Axis,
): Coordinates => {
  const spacing = SPACING[draggable.descriptor.type] ?? 0;
  const capsuleOffset =
    draggable.descriptor.type === DND_TYPES.CAPSULE &&
    draggable.descriptor.index === 0
      ? 16
      : 0;
  const rect = getClientRect(draggable.element);
  const displacement = rect[axis.size] + spacing - capsuleOffset;
  return patch(axis.line, displacement);
};

const SPACING: { [type: string]: number } = {
  [DND_TYPES.CAPSULE]: 16,
  [DND_TYPES.COLUMN]: 16,
};
