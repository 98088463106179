import { createSafeContext } from "@/services/hooks/useSafeContext";

import type { Direction } from "./beautiful-dnd";

export type DroppableContextValue = {
  droppableId: string;
  direction: Direction;
  type: string;
};

export const DroppableContext = createSafeContext<DroppableContextValue>();

export const useDroppableContext = DroppableContext.makeSafeHook(
  "useDroppableContext",
  "Droppable",
);
